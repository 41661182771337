import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN_INTERFACE } from "../interfaces/login";

const endPoint = process.env.REACT_APP_API_URL as string;

interface LoginResponse {
    access: string;
    user: object; // Adjust the type based on your actual user object structure
}

export const login = createAsyncThunk(
    "login",
    async ({ username, password }: LOGIN_INTERFACE, thunkApi) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const res = await axios.post<LoginResponse>(
                `${endPoint}/rest-auth/login/`,
                { username, password },
                config
            );

            localStorage.setItem("token", res.data.access);
            localStorage.setItem("user", JSON.stringify(res.data.user));

            return thunkApi.fulfillWithValue(res);
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data);
        }
    }
);
